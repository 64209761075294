<template>
  <div id="RuleSystem" class="data-manager-wrap container">
    <div class="C_box">
      <div class="zdy">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        自定义规则
      </div>
      <div class="row justify-content-between mt-3">
        <div>
          <el-button-group>
            <el-button
              v-if="getUserRole('custom_whitewash')"
              size="medium"
              :type="active ? 'primary' : ''"
              @click="normal(true)"
              >自定义粉饰点</el-button
            >
            <el-button
              v-if="getUserRole('custom_risk')"
              size="medium"
              :type="!active ? 'primary' : ''"
              @click="normal(false)"
              >自定义风险点</el-button
            >
          </el-button-group>
        </div>
        <div>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="addRule"
            >添加规则</el-button
          >
        </div>
      </div>
      <div class="mt-3">
        <el-table
          :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
          style="margin-top: 10px; width: 100%; color: #333; font-size: 16px"
          :data="tableData"
          stripe
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            :label="active ? '异常点名称' : '风险点名称'"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="detail"
            :label="active ? '异常点说明' : '风险点说明'"
            width="280"
          ></el-table-column>
          <el-table-column
            prop="description"
            :label="active ? '异常点描述' : '风险点描述'"
          ></el-table-column>
          <el-table-column label="风险等级" width="120">
            <template slot-scope="{ row }">
              <el-tag
                v-show="row.level == 0"
                style="color: #3a85fd; border-color: #3a85fd; font-size: 14px"
                effect="plain"
                >提示信息</el-tag
              >
              <el-tag
                v-show="row.level == 1"
                style="color: #ffa105; border-color: #ffa105; font-size: 14px"
                effect="plain"
                >需要关注</el-tag
              >
              <el-tag
                v-show="row.level == 2"
                style="color: #f14c5d; border-color: #f14c5d; font-size: 14px"
                effect="plain"
                >重点关注</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="启用状态" width="180px">
            <template slot-scope="{ row }">
              <span v-if="row.is_disabled" style="color: #f14c5d">禁用</span>
              <span v-else style="color: #2fc25b">启用</span>
              <span
                v-show="row.scope == 1"
                :style="
                  row.is_disabled ? { color: '#F14c5D' } : { color: '#2FC25B' }
                "
              >
                (全部公司)</span
              >
              <span
                v-show="row.scope == 2"
                :style="
                  row.is_disabled ? { color: '#F14c5D' } : { color: '#2FC25B' }
                "
              >
                (非上市公司)</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="updated_at" label="更新日期" width="180">
            <template slot-scope="{ row }">
              {{
                row.updated_at.substring(0, 10) +
                " " +
                row.updated_at.substring(11, 16)
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="{ row }">
              <a style="color: #3a85fd" @click="edit(row)">编辑</a>
              <a
                style="color: #3a85fd"
                class="ml-3"
                @click="disabledClick(row)"
              >
                <span v-show="!row.is_disabled">禁用</span>
                <span v-show="row.is_disabled">启用</span>
              </a>
              <a style="color: #3a85fd" class="ml-3" @click="deleteClick(row)"
                >删除</a
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right mt-4">
          <v-pagination
            ref="pagination"
            :total="Total"
            :pageSize="size"
            :pageIndex="page"
            @page-change="myDataNextPage"
            @page-size-change="pageSizeChange"
            size="middle"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_rule,
  get_rule_wh,
  deleteRule, //粉饰删除
  fxdeleteRule, //风险删除
  putRule,
  fxputRule,
} from "@/api.js";

export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "应付款项异常",
          address: "上海市普陀区金沙江路 1518 弄",
          desc: "上海市普陀区金沙江路 1518 弄",
          tag: "2",
          type: 0,
          types: 0,
        },
        {
          date: "2016-05-02",
          name: "应付款项异常",
          address: "上海市普陀区金沙江路 1518 弄",
          desc: "上海市普陀区金沙江路 1518 弄",
          tag: "1",
          type: 1,
          types: 1,
        },
        {
          date: "2016-05-02",
          name: "应付款项异常",
          address: "上海市普陀区金沙江路 1518 弄",
          desc: "上海市普陀区金沙江路 1518 弄",
          tag: "3",
          type: 2,
          types: 2,
        },
        {
          date: "2016-05-02",
          name: "应付款项异常",
          address: "上海市普陀区金沙江路 1518 弄",
          desc: "上海市普陀区金沙江路 1518 弄",
          tag: "1",
          type: 1,
          types: 1,
        },
      ],
      active: false,
      page: "1",
      size: "10",
      Total: 0,
    };
  },
  mounted() {
    let type = this.getUserRole("custom_whitewash");
    if (
      (this.$route.query.type == 0 && type) ||
      (this.$route.query.type == undefined && type)
    ) {
      this.normal(true);
    } else {
      this.normal(false);
    }
  },
  methods: {
    addRule() {
      this.$router.push({
        name: "RuleEdit",
        query: { type: 0 },
      });
    },
    //编辑
    edit(row) {
      sessionStorage.setItem("ruleEdit", JSON.stringify(row));
      setTimeout(() => {
        this.$router.push({
          name: "RuleEdit",
          query: { type: 1 },
        });
      }, 500);
    },

    normal(type) {
      this.active = type;
      sessionStorage.setItem("AC", type);
      if (type) {
        this.page = 1;
        this.size = 10;
        this.getYC();
      } else {
        this.page = 1;
        this.size = 10;
        this.getFX();
      }
    },
    // 获取异常点列表
    getYC() {
      let param = {
        type: "1",
        size: this.size,
        page: this.page,
      };
      get_rule_wh(param).then(({ data }) => {
        // console.log(data);
        this.tableData = data.result;
        this.Total = data.total;
      });
    },

    // 获取风险点列表
    getFX() {
      let param = {
        type: "0",
        size: this.size,
        page: this.page,
      };
      get_rule(param).then(({ data }) => {
        this.tableData = data.result;
        this.Total = data.total;
      });
    },
    disabledClick(row) {
      // console.log(row);

      // 异常点
      if (row.style == "1") {
        let params = {
          name: row.name,
          industry_code: row.industry_code,
          description: row.description,
          detail: row.detail,
          level: row.level,
          scope: row.scope,
          style: row.style,
          formula: row.formula,
          is_disabled: !row.is_disabled,
        };
        putRule(row.id, params).then(({ data }) => {
          this.$message.success("更新成功！");
          this.getYC();
        });
      } else {
        let params = {
          name: row.name,
          industry_code: row.industry_code,
          description: row.description,
          // "detail": row.detail,
          level: row.level,
          scope: row.scope,
          style: row.style,
          formula: row.formula,
          is_disabled: !row.is_disabled,
        };
        fxputRule(row.id, params).then(({ data }) => {
          this.$message.success("更新成功！");
          this.getFX();
        });
      }
    },
    // 删除
    deleteClick(row) {
      // console.log(row);
      this.$Modal.confirm({
        title: "是否删除此条规则？",
        onOk: () => {
          // 粉饰
          if (this.active) {
            deleteRule(row.id).then(({ data }) => {
              this.$message.success("删除成功！");
            });
            this.getYC();
          } else {
            // 风险
            fxdeleteRule(row.id).then(({ data }) => {
              this.$message.success("删除成功！");
            });
            this.getFX();
          }
        },
        onCancel: () => {},
      });
    },
    myDataNextPage(value) {
      // console.log(value);
      this.page = value;
      if (this.active) {
        this.getYC();
      } else {
        this.getFX();
      }
    },
    pageSizeChange(value) {
      // console.log(value);
      this.size = value;
      if (this.active) {
        this.getYC();
      } else {
        this.getFX();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}

#RuleSystem {
  .C_box {
    background: #fff;
    padding: 15px 20px 20px 20px;
    border-radius: 4px;

    .zdy {
      font-size: 20px;
      font-weight: 550;
      background: #fff;
      color: #333;
    }
  }
}
</style>
